<template>
  <div id="app">
    <!-- <v-overlay :value="loading">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay> -->

    <!-- loading skeleton start -->

    <!-- loading skeleton end -->

    <v-col v-show="this.valid == false">
      <v-alert text type="error">
        Kamu tidak punya akses Ke halaman "KategoriAbsen"
      </v-alert>
    </v-col>

    <v-col v-show="this.valid == true">
      <!-- Alert Data Tersimpan -->
      <v-alert style="position:fixed;z-index:2;right:20px;"
        dismissible
        color="success"
        border="left"
        elevation="4"
        colored-border
        v-model="Alert"
        transition="slide-x-transition"
      >
        <v-icon class="mr-1">check-circle</v-icon>
        Data Berhasil Disimpan
      </v-alert>

      <!-- Alert Data Sudah Terhapus -->
      <v-alert style="position:fixed;z-index:2;right:20px;"
        dismissible
        color="error"
        border="left"
        elevation="4"
        colored-border
        v-model="AlertDataTerhapus"
        transition="slide-x-transition"
      >
        Data Berhasil Dihapus
      </v-alert>

      <!-- Alert Data Berhasil Terubah -->
      <v-alert style="position:fixed;z-index:2;right:20px;"
        dismissible
        color="info"
        border="left"
        elevation="4"
        colored-border
        v-model="AlertBerhasilTerubah"
        transition="slide-x-transition"
      >
        Data Berhasil Diubah
      </v-alert>

      <v-sheet outlined class="elevation-3 pa-2">
        <v-toolbar
          flat
          outlined
          height="55"
          color="dark"
          v-resize="onResize"
        >
          <v-toolbar-title>
            <label>Kategori Absen</label>
          </v-toolbar-title>
          <v-spacer></v-spacer>

          <!-- Menu Export Data -->
          <div class="text-center mx-2">
            <v-menu
              v-model="MenuExport"
              :close-on-content-click="false"
              :nudge-width="1"
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <!-- Btn Export Mode Biasa -->
                <v-btn
                  :disabled="loading == true"
                  @click="MenuExport = !MenuExport"
                  class="text-capitalize"
                  v-show="mobile == false"
                  v-bind="attrs"
                  v-on="on"
                  outlined
                  text
                >
                  <v-icon class="ml-n2 mr-1">mdi-table-arrow-right</v-icon>
                  <span class="subtitle-1">Export Data</span>
                  <v-icon class="ml-1 mr-n3">{{ MenuExport ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                </v-btn>
              </template>

              <v-card outlined height="100" class="pa-2" v-show="MenuExport">
                <!-- PDF -->
                <div class="mb-2">
                  <v-btn
                    text
                    block
                    outlined
                    @click.native="PdfExport"
                    class="text-capitalize subtitle-1"
                  >
                    Pdf
                    <v-icon right>mdi-adobe-acrobat</v-icon>
                  </v-btn>
                </div>

                <!-- EXCEL -->
                <div>
                  <v-btn
                    text
                    block
                    outlined                       
                    @click.native="excelExport"
                    class="text-capitalize subtitle-1"
                  >
                    Excel
                    <v-icon right>mdi-microsoft-excel</v-icon>
                  </v-btn>
                </div>
              </v-card>
            </v-menu>

            <!-- Btn Export Dialog Mode Mobile -->
            <v-dialog
              v-model="DialogPilihExport"
              max-width="200"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  :disabled="loading == true"
                  v-show="mobile == true"
                  v-bind="attrs"
                  v-on="on"
                  outlined
                  small
                  text
                  fab
                >
                  <v-icon>mdi-table-arrow-right</v-icon>
                </v-btn>
              </template>
              <v-card class="rounded-lg">
                <v-toolbar dense>
                  <v-layout justify-center>
                    <v-toolbar-title>
                      Pilih Export File
                    </v-toolbar-title>
                  </v-layout>
                </v-toolbar>
                <v-card class="pa-2">
                  <v-btn text block outlined class="text-capitalize subtitle-1 mb-2" @click.native="PdfExport">
                    Pdf
                    <v-icon right>mdi-adobe-acrobat</v-icon>
                  </v-btn>

                  <v-btn text block outlined class="text-capitalize subtitle-1" @click.native="excelExport">
                    Excel
                    <v-icon right>mdi-microsoft-excel</v-icon>
                  </v-btn>
                </v-card>
              </v-card>
            </v-dialog>
          </div>

          <!-- Dialog Tambah Jabatan -->
          <v-dialog
            v-model="DialogKategoriAbsen"
            max-width="350px"
            scrollable
            persistent
          >
            <template v-slot:activator="{ on, attrs }">
              <!-- Btn Tambah Mode Desktop -->
              <v-btn
                class="text-capitalize mr-n2"
                v-show="mobile == false && ValidCreate == true"
                v-bind="attrs"
                v-on="on"
                outlined
                :disabled="loading == true"
                text
              >
                <v-icon class="ml-n2 mr-1">mdi-plus-thick</v-icon>
                <span class="mr-n1 subtitle-1">Tambah</span>
              </v-btn>

              <!-- Btn Tambah Mode Mobile -->
              <v-btn 
                class="mr-n2"
                v-show="mobile == true && ValidCreate == true"
                v-bind="attrs"
                v-on="on"
                outlined
                small
                text
                fab
              >
                <v-icon>mdi-plus-thick</v-icon>
              </v-btn>
            </template>
            <v-card class="rounded-lg">
              <v-toolbar flat height="65">
                <v-toolbar-title class="title">
                  <span class="text-h5">{{ formTitleKategoriAbsen }}</span>
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn
                  fab
                  text
                  small
                  @click="KeluarDialogKategoriAbsen"
                >
                  <v-icon>mdi-window-close</v-icon>
                </v-btn>
              </v-toolbar>
              <v-divider></v-divider>
              
              <v-card-text>
                <div class="mr-n3 ml-n3 mb-n2 mt-3">
                  <v-sheet outlined class="rounded-lg pa-5">
                    <v-row>                                   
                      <v-col cols="12" sm="12" md="12">
                        <v-text-field
                          dense
                          clearable
                          label="Kategori"
                          color="dark"
                          v-model="editedItem.Kategori"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" sm="6" md="12">
                        <v-text-field
                          dense
                          clearable
                          label="Jam Masuk"
                          color="dark"
                          v-model="editedItem.JamMasuk"
                          type="time"
                        ></v-text-field>
                      </v-col>

                      <!-- <v-col cols="12" sm="12" md="12">
                        <v-dialog
                          ref="DialogJamMasuk"
                          v-model="DialogJamMasuk"
                          :return-value.sync="time"
                          persistent
                          width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="editedItem.JamMasuk"
                              label="Jam Masuk"
                              prepend-icon="mdi-clock-time-four-outline"
                              readonly
                              dense
                              color="dark"
                              v-bind="attrs"
                              v-on="on"
                              hint="Wajib Diisi." 
                              :rules="[value => !!value || 'Tidak Boleh Kosong']"
                            ></v-text-field>
                          </template>
                          <v-time-picker
                            v-if="DialogJamMasuk"
                            v-model="editedItem.JamMasuk"
                            format="24hr"
                            full-width
                            scrollable
                            class="rounded-lg"
                            color="red darken-4"
                          >
                            <v-spacer></v-spacer>
                              <v-btn
                                text
                                color="red darken-4"
                                class="text-capitalize"
                                @click="DialogJamMasuk = false"
                              >
                                Batal
                              </v-btn>
                              <v-btn
                                text
                                color="red darken-4"
                                class="text-capitalize"
                                @click="$refs.DialogJamMasuk.save(time)"
                              >
                                OK
                              </v-btn>
                          </v-time-picker>
                        </v-dialog>
                      </v-col> -->

                      <v-col cols="12" sm="6" md="12">
                        <v-text-field
                          dense
                          clearable
                          label="Jam Pulang"
                          color="dark"
                          v-model="editedItem.JamPulang"
                          type="time"
                        ></v-text-field>
                      </v-col>

                      <!-- <v-col cols="12" sm="12" md="12">
                        <v-dialog
                          ref="DialogJamPulang"
                          v-model="DialogJamPulang"
                          :return-value.sync="time"
                          persistent
                          width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="editedItem.JamPulang"
                              label="Jam Pulang"
                              prepend-icon="mdi-clock-time-four-outline"
                              readonly
                              dense
                              color="dark"
                              v-bind="attrs"
                              v-on="on"
                              hint="Wajib Diisi." 
                              :rules="[value => !!value || 'Tidak Boleh Kosong']"
                            ></v-text-field>
                          </template>
                          <v-time-picker
                            v-if="DialogJamPulang"
                            v-model="editedItem.JamPulang"
                            format="24hr"
                            full-width
                            scrollable
                            class="rounded-lg"
                            color="red darken-4"
                          >
                            <v-spacer></v-spacer>
                              <v-btn
                                text
                                color="red darken-4"
                                class="text-capitalize"
                                @click="DialogJamPulang = false"
                              >
                                Batal
                              </v-btn>
                              <v-btn
                                text
                                color="red darken-4"
                                class="text-capitalize"
                                @click="$refs.DialogJamPulang.save(time)"
                              >
                                OK
                              </v-btn>
                          </v-time-picker>
                        </v-dialog>
                      </v-col> -->
                    </v-row>
                  </v-sheet>
                </div>             
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <span class="d-inline-block text-truncate">
                  <label>Status : <span>{{Status}}</span></label>
                </span>
                <v-spacer></v-spacer>

                <v-btn
                  dark
                  depressed
                  color="red darken-4 mx-n2"
                  class="text-capitalize"
                  @click="Simpan"
                >
                  <v-icon left>mdi-content-save</v-icon>
                  <span class="mr-1">Simpan</span>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <div v-show="loading == true">
            <v-progress-linear
              :active="loading"
              :indeterminate="loading"
              absolute
              bottom
              height="6"
              color="red darken-4"
            ></v-progress-linear>
          </div>
        </v-toolbar>

        <div>
          <ejs-grid
            ref='gridkategoriabsen'
            style="height: 100%;"
            height="43vh"
            gridLines='Both'
            id="kategoriabsenview"
            :dataSource="DataKategoriAbsen"
            :allowPaging="true"
            :allowSorting='true'
            :allowResizing='true'
            :allowGrouping='true'
            :toolbar='toolbarOptions'
            :commandClick="commandClick"
            :pageSettings='pageSettings'
            :allowExcelExport='true'
            :allowPdfExport='true'
          >
            <e-columns>
              <e-column headerText="Aksi" textAlign='Center' width="120" :commands="commands" :lockColumn='true' :customAttributes="customAttributes">
                <div class="btn-group">
                  <v-btn type="button" class="btn btn-default" (click)="prediemRowEdit($event)">
                    <i class="fa fa-pencil"></i>
                  </v-btn>
                  <v-btn type="button" class="btn btn-default" (click)="prediemRowDelete($event)">
                    <i class="fa fa-trash"></i>
                  </v-btn>
                </div>
              </e-column>
              <e-column field='KodeAbsen' headerText='Kode Absen' textAlign='Left' width=150></e-column>
              <e-column field='Kategori' headerText='Kategori' width=170></e-column>
              <e-column field='JamMasuk' headerText='Jam Masuk' width=150></e-column>
              <e-column field='JamPulang' headerText='Jam Pulang' textAlign='Left' width=150></e-column>
              <e-column field='DiBuatOleh' headerText='DiBuatOleh' textAlign='Left' width=150></e-column>
              <e-column field='DiBuatTgl' headerText='DiBuatTgl' textAlign='Left' width=150></e-column>
              <e-column field='DiUbahOleh' headerText='DiUbahOleh' textAlign='Left' width=150></e-column>
              <e-column field='DiUbahTgl' headerText='DiUbahTgl' textAlign='Left' width=150></e-column>
            </e-columns>
          </ejs-grid>
        </div>
      </v-sheet>
    </v-col>
  </div>
</template>
<script>
import Vue from "vue";
import { GridPlugin, Page, Sort, Group, Resize, Toolbar, Search, CommandColumn, ExcelExport, PdfExport } from "@syncfusion/ej2-vue-grids";
import api from "@/services/http";
Vue.use(GridPlugin);

export default {
  data() {
    return {
      time: null,
      DialogJamMasuk: false,
      DialogJamPulang: false,
      mobile:null,
      valid:null,
      Permission:[],
      ValidCreate:false,
      KodeAbsen:null,
      textAktif:true,
      umenu:[],
      loading:true,
      Kode:null,
      DialogPilihExport:false,
      windowSize: {x: 0, y: 0},
      MenuExport: false,
      commands: [],
      customAttributes : {class: 'customcss'},
      Alert:false,
      akun:[],
      usermenu:null,
      AlertDataTerhapus:false,
      AlertBerhasilTerubah:false,
      user:[],
      token:null,
      DialogKategoriAbsen: false,
      form: new FormData,
      editedIndex: -1,

      defaultItem: {
        id:"",
        KodeAbsen: "",
        Kategori: "",
        JamMasuk: "",
        JamPulang: "",
      },
      editedItem: {
        id:"",
        KodeAbsen: "",
        Kategori: "",
        JamMasuk: "",
        JamPulang: "",
      },
      toolbarOptions: ['Search'],
      DataKategoriAbsen: [],
      pageSettings: {pageSize: 10, pageSizes :['5','10','15','20','50','All']},
    };
  },
  

  provide: {
    grid: [Page, Sort, Group, Resize, Toolbar, CommandColumn, Search,ExcelExport,PdfExport ]
  },

 async mounted(){
    this.token = localStorage.getItem('token')
    this.user = JSON.parse(localStorage.getItem('user'))
    // await Promise.all([this.saya()])
    if (this.Permission.some(a => a == 'R')) {
      this.valid = true
      this.getdata()
    }else{
      this.loading = false
      this.valid = false
    }
    if (this.Permission.some(a => a == 'C')) {
     this.ValidCreate = true
    }
    if (this.Permission.some(a => a == 'U')) {
     this.commands.push({buttonOption: { cssClass: "e-flat Edit", iconCss: "e-edit e-icons", content:' '} })
    }
    if (this.Permission.some(a => a == 'D')) {
     this.commands.push({ buttonOption: { cssClass:"e-flat Delete", iconCss: "e-delete e-icons", content:' '} })
    }
  },

  created(){
    //get action for this page
    console.log('param',this.$route.params.Permission)
    let permisi = this.$route.params.Permission
    this.Permission = permisi == null ? [] : permisi.split(',')
    },

  computed: {
    formTitleKategoriAbsen() {
      return this.editedIndex === -1 ? 'Tambah Kategori Absen' : 'Ubah Kategori Absen'
    },
    Status () {
      return this.editedIndex === -1 ? 'Baru' : 'Ubah'
    },
  },
  
  watch: {
    // DialogJamMasuk(){
    //   console.log(this.editedItem.JamMasuk)
    // },
      // let a = this.DataKategoriAbsen.filter( function(item){return (item.UserMenu == newval.toUpperCase());} )
      // this.umenu = a
    windowSize(){
      if (this.windowSize.x < 450) {
        this.titleClass = "d-none"
        this.mobile = true
      }else{
        this.titleClass = "mr-4"
        this.mobile = false
      }
    },

    DialogKategoriAbsen (val) {
      val || this.KeluarDialogKategoriAbsen()
    },

    Alert(){
      this.AlertItem()
    },
    AlertDataTerhapus(){
      this.AlertItem()
    },
    AlertBerhasilTerubah(){
      this.AlertItem()
    },
  },

  methods: {
    onResize(){
      this.windowSize = { x: window.innerWidth, y: window.innerHeight }
    },
    cariKode(Kode){
      if (Kode != null) {
          this.textAktif = false
        }else{
          this.textAktif = true
        }
      if (Kode == null || Kode == '' || Kode.length == 0) {
        this.editedIndex = -1
          this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      }else{
      this.Kode = Kode.toUpperCase()
      let a = this.DataKategoriAbsen.filter( function(item){return (item.Kode == Kode.toUpperCase());} );
      if (a.length > 0) {
          this.editedIndex = 1;
          this.textAktif = false
          this.editedItem.Nama = a[0].Nama
          // this.editedItem.UserMenu = a[0].UserMenu
        }else{
          //do nothing
        }
      }
    },
  async saya(){
       if (this.$menu == undefined) {
         await api.get('/menu?token='+this.token,{ headers: {"Authorization" : `Bearer ${this.token}`} }).then(
        res=>{
          if (res) {
            this.akun = res.data
            let route = this.$route.name
            let a = this.akun.filter( function(item){return (item.Route == route);} );
            if (a.length > 0) {
               this.valid = true
            }else{
              this.valid = false
              this.loading = false
            }
            //do noting
          }
        }).catch(err=>{
          // alert(err)
          if (err == "Error: Request failed with status code 401" && this.$route.path != "/login") {
            this.logout()
          }
        })
       }else{
        this.akun = this.$menu
        let route = this.$route.name
            let a = this.akun.filter( function(item){return (item.Route == route);} );
            if (a.length > 0) {
               this.valid = true
            }else{
              this.valid = false
              this.loading = false
            }
       }     
    },
      // cekmenu(){
        
      // },

    Simpan(){
      if(this.formTitleKategoriAbsen === "Tambah Kategori Absen"){
        this.Toast('Data Disimpan')
          api.post('/kategoriabsen?token='+this.token,{
            Kategori: this.editedItem.Kategori,
            JamMasuk: this.editedItem.JamMasuk.replace(":", "."),
            JamPulang: this.editedItem.JamPulang.replace(":", "."),
            },{ headers: { 'Content-Type': 'multipart/form-data',"Authorization" : `Bearer ${this.token}` } })
            .then((res)=>{
              if (res) {
                // this.Alert = true
                this.getdata()
              } else {
                //
              }
            })
      }else if(this.formTitleKategoriAbsen === "Ubah Kategori Absen"){
        this.Toast("Data Diubah")
         api.put("/kategoriabsen/"+this.editedItem.id+'/?token='+this.token,{
            KodeAbsen: this.editedItem.KodeAbsen,
            Kategori: this.editedItem.Kategori,
            JamMasuk: this.editedItem.JamMasuk.replace(":", "."),
            JamPulang: this.editedItem.JamPulang.replace(":", "."),
					},
          { headers: {"Authorization" : `Bearer ${this.token}` } })
					.then((res) => {
						if (!res) {
							//do nothing
						}else{
              this.getdata()
              // this.AlertBerhasilTerubah = true
						}
						
					})
      }
      this.KeluarDialogKategoriAbsen()    
    },

    commandClick: function(args) {
      if (args.target.classList.contains("custombutton")){
        // let data = JSON.stringify(args.rowData);
		
        // console.log(args.rowData);
      } else if (args.target.classList.contains("Delete")){
        var r = confirm("Yakin Hapus Data?");
        if (r == true) { 
          this.Toast('Data Dihapus')
          api
            .delete("/kategoriabsen/"+args.rowData.id+'?token='+this.token)
            .then((res) =>{
              // console.log(res);
              if (res) {
                 this.getdata()
              // this.AlertDataTerhapus = true
              }
             
            })
            .catch((err) => {
              console.log(err);
            });
        }
      } else if (args.target.classList.contains("Edit")){
        this.KodeAbsen = args.rowData.kodeabsen
        let data = args
            this.editedIndex = 1;
            // console.log(data)
            this.editedItem.id = data.rowData.id
            this.editedItem.KodeAbsen = data.rowData.kodeabsen
            this.editedItem.Kategori = data.rowData.kategori
            this.editedItem.JamMasuk = data.rowData.jammasuk.replace(".", ":")
            this.editedItem.JamPulang = data.rowData.jampulang.replace(".", ":")
            this.DialogKategoriAbsen = true

          // if (args.rowData.Aktif == 0) {
          //   this.dataTemp = args.rowData
          //   this.dialogAktifData = true
          //   }else{
          //       let data = args;
          //       this.editedIndex = 1;
          //       this.Kode = data.rowData.Kode
          //       this.editedItem.Nama = data.rowData.Nama
          //       this.editedItem.Password = data.rowData.Password
          //       this.editedItem.UserMenu = data.rowData.UserMenu
          //       // this.dialogWO = true;
          //   }
      }
    },

    PdfExport(){
      let data = document.getElementById('kategoriabsenview').ej2_instances[0];
      // console.log(data)
      data.pdfExportProperties = {
        pageOrientation: 'Landscape',
        fileName:"ReportAbsensi.pdf"
      }
      data.columns[1].visible = false
      data.columns[4].visible = false
      data.columns[3].visible = false
      // let pdfExportProperties = {
       
      // };
      // this.$refs.gridkategoriabsen.columns[0].visible = false;
      // this.$refs.gridkategoriabsen.columns[1].visible = true;
      this.$refs.gridkategoriabsen.pdfExport(data.pdfExportProperties);
    },
    excelExport(){
      this.$refs.gridkategoriabsen.excelExport();
      // console.log(this.$refs.gridkategoriabsen)
      // let data = document.getElementById('kategoriabsenview').ej2_instances[0];
      // console.log(data)
      // data.excelExport();
    },

    KeluarDialogKategoriAbsen () {
      this.DialogKategoriAbsen = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
        this.Kode = null
        this.textAktif = true
      })
    },

    // beforeExport: function(args) {
      
    // },

    getdata(){
      if (this.valid == true) {
        api.get('/kategoriabsen?token='+this.token,{ headers: {"Authorization" : `Bearer ${this.token}`} }).then(
				res => {
					// console.log(res);
          if (res) {
            this.DataKategoriAbsen = res.data
          this.loading = false
          }
          
				},
				err => {
					console.log(err);
				}
			)
      }
      
    },

    Toast(data){
      this.$swal.fire({
      toast: true,
      icon: 'success',
      title: data,
      animation: false,
      position: 'top-right',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true})
    },

    Reset() {
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
      })
    },
    logout(){
        api.post('/logout', this.token)
        .then(res=>{
          // console.log(res)
          if (res) {
            //do nothing
          }
        })
        localStorage.removeItem('token')
        localStorage.removeItem('user')
        location.reload(false)
      },

    AlertItem(){
      window.setInterval(() => {
        this.Alert = false;
        this.AlertDataTerhapus = false;
        this.AlertBerhasilTerubah = false;
      }, 3000)
    },
  },
}
</script>

<style scoped>
.custom-file-input {
  border: 1px solid #ccc;
  display: inline-block solid #ccc;
  padding: 6px 6px 6px;
  cursor: pointer;
}

.e-grid .e-rowcell.customcss{
  background-color: #d6d6d6;
}
.e-grid .e-headercell.customcss{
  background-color: #ecedee;
}
</style>


{% endraw %}